import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Skeleton from './Skeleton';

const SkeletonJigScreen = () => {
  const isMobile = window.innerWidth <= 768; // Set your mobile breakpoint here

  return (
    <div>
      <br />
      {isMobile ? (
        <div>
          {/* Mobile view */}
          <Row className='box'>
            <Skeleton classes='title width-25 skeleton-title' />
            <Skeleton classes='title width-100 skeleton-text' />
            <Skeleton classes='title width-100 skeleton-text mb-3' />
          </Row>

          <Row>
            {/* right side */}
            <Col md={6}>
              <Row className='box'>
                <div className='skeleton-image' />
              </Row>
              <Row className='box'>
                <div className='skeleton-image' />
              </Row>
              <Row className='box'>
                <div className='skeleton-image' />
              </Row>
            </Col>

            {/* left side */}
            <Col md={6}>
              <Row className='box'>
                <div className='skeleton-image' />
              </Row>
              <Row className='box'>
                <div className='skeleton-image' />
              </Row>
              <Row className='box'>
                <div className='skeleton-image' />
              </Row>
            </Col>
          </Row>
        </div>
      ) : (
        <div>
          {/* desktop view */}
          <Row className='box'>
            <Skeleton classes='title width-25 skeleton-title' />
            <Skeleton classes='title width-100 skeleton-text' />
            <Skeleton classes='title width-100 skeleton-text mb-3' />
          </Row>

          <Row>
            {/* right side */}
            <Col md={6}>
              <Row className='box'>
                <div className='skeleton-image' />
              </Row>
              <Row className='box'>
                <div className='skeleton-image' />
              </Row>
              <Row className='box'>
                <div className='skeleton-image' />
              </Row>
            </Col>

            {/* left side */}
            <Col md={6}>
              <Row className='box'>
                <div className='skeleton-image' />
              </Row>
              <Row className='box'>
                <div className='skeleton-image' />
              </Row>
              <Row className='box'>
                <div className='skeleton-image' />
              </Row>
            </Col>
          </Row>
        </div>
      )}
      <br />
    </div>
  );
};

export default SkeletonJigScreen;
