import React from 'react';
import { Row, Col } from 'react-bootstrap';

const SkeletonDesignScreen = () => {
  return (
    <>
      <Row>
        <Col md={12}>
          <div className='box'>
            <div className='skeleton-image'></div>
          </div>
          <div className='box'>
            <div className='skeleton-title'></div>
            <div className='skeleton-paragraph'></div>
            <div className='skeleton-paragraph'></div>
          </div>
          <div className='box'>
            <div className='skeleton-title'></div>
            <div className='skeleton-paragraph'></div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div className='box'>
            <div className='skeleton-title'></div>
            <div className='skeleton-paragraph'></div>
          </div>
        </Col>
        <Col md={6}>
          <div className='box'>
            <div className='skeleton-title'></div>
            <div className='skeleton-paragraph'></div>
          </div>
        </Col>
        <Col md={6}>
          <div className='box'>
            <div className='skeleton-title'></div>
            <div className='skeleton-paragraph'></div>
          </div>
        </Col>
        <Col md={6}>
          <div className='box'>
            <div className='skeleton-title'></div>
            <div className='skeleton-paragraph'></div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div className='box'>
            <div className='skeleton-image'></div>
          </div>
        </Col>
        <Col md={6}>
          <div className='box'>
            <div className='skeleton-title'></div>
            <div className='skeleton-paragraph'></div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SkeletonDesignScreen;
