import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Skeleton from './Skeleton';

const SkeletonShippingAddressScreen = () => {
  return (
    <div className='content'>
      <br />
      <div className='small-container'>
        <Row className='justify-content-center'>
          <Col md={4} className='box'>
            <Skeleton classes='title width-50 skeleton-title' />
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col md={4} className='box'>
            <Skeleton classes='title width-25 skeleton-title' />
            <Skeleton classes='title width-50 skeleton-text mb-3' />
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col md={4} className='box'>
            <Skeleton classes='title width-25 skeleton-title' />
            <Skeleton classes='title width-50 skeleton-text mb-3' />
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col md={4} className='box'>
            <Skeleton classes='title width-25 skeleton-title' />
            <Skeleton classes='title width-50 skeleton-text mb-3' />
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col md={4} className='box'>
            <Skeleton classes='title width-25 skeleton-title' />
            <Skeleton classes='title width-50 skeleton-text mb-3' />
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col md={4} className='box'>
            <Skeleton classes='title width-25 skeleton-title' />
            <Skeleton classes='title width-50 skeleton-text mb-3' />
          </Col>
        </Row>
      </div>
      <br />
    </div>
  );
};

export default SkeletonShippingAddressScreen;
