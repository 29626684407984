import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import Skeleton from './Skeleton';

const SkeletonProfileScreen = () => {
  const isMobile = window.innerWidth <= 768; // Set your mobile breakpoint here

  return (
    <div>
      <br />
      {isMobile ? (
        <div className='mobile-image-card'>
          <Col>
            <Card className='mobile-image-card'>
              <Col>
                <Skeleton classes='title width-25 skeleton-title' />
                <Skeleton classes='title width-100 skeleton-text mb-3' />
              </Col>
            </Card>

            <Card className='mobile-image-card'>
              <Col>
                <Skeleton classes='title width-25 skeleton-title' />
                <Skeleton classes='title width-100 skeleton-text mb-3' />
              </Col>
            </Card>

            <Card className='mobile-image-card'>
              <Col>
                <Skeleton classes='title width-25 skeleton-title' />
                <Skeleton classes='title width-100 skeleton-text mb-3' />
              </Col>
            </Card>

            <Card className='mobile-image-card'>
              <Col>
                <Skeleton classes='title width-25 skeleton-title' />
                <Skeleton classes='title width-100 skeleton-text mb-3' />
              </Col>
            </Card>
          </Col>
        </div>
      ) : (
        <div className='small-screen'>
          {/* Desktop view */}
          <Row md={12}>
            <Col className='box' md={12}>
              <Skeleton classes='title width-25 skeleton-title' />
            </Col>
            <Col className='box' md={12}>
              <Skeleton classes='title width-25 skeleton-title' />
              <Skeleton classes='title width-100 skeleton-text mb-3' />
            </Col>
            <Col className='box' md={12}>
              <Skeleton classes='title width-25 skeleton-title' />
              <Skeleton classes='title width-100 skeleton-text mb-3' />
            </Col>
            <Col className='box' md={12}>
              <Skeleton classes='title width-25 skeleton-title' />
              <Skeleton classes='title width-100 skeleton-text mb-3' />
            </Col>
            <Col className='box' md={12}>
              <Skeleton classes='title width-25 skeleton-title' />
              <Skeleton classes='title width-100 skeleton-text mb-3' />
            </Col>
          </Row>
        </div>
      )}
      <br />
    </div>
  );
};

export default SkeletonProfileScreen;
